import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

const ProjectCard = ({title, description, tags, href}) => {
  return (
    <div className="ProjectCard card">
      <div className='card-content'>
        <h3 className='title is-4 primary subdisplay'>{title}</h3>
        <p>{description}</p>
        <div className='ProjectCard-foot'>
          <div className='ProjectCard-tags mono'>
            {tags.map((t,i) => <span key={i}>{t}</span>)}
          </div>
          {href && (
            <div className='ProjectCard-links'>
              <a
                className="ProjectCard-link"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FA icon="external-link-alt" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  href: PropTypes.string,
}

ProjectCard.defaultProps = {
  title: 'Title',
  description: 'Description text.',
  tags: [],
  href: null,
}

export default ProjectCard
