import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

const ProjectFeature = ({title, description, tags, github, href, image}) => {

  const Title = () => (
    <h3 className="title is-4 subdisplay accent bold">{title}</h3>
  )

  const Links = () => (
    <>
      {github && (
        <a className="ProjectFeature-link" href={github} target="_blank" rel="noopener noreferrer">
          <FA icon={['fab', 'github']} />
        </a>
      )}
      {href && (
        <a className="ProjectFeature-link" href={href} target="_blank" rel="noopener noreferrer">
          <FA icon="external-link-alt" />
        </a>
      )}
    </>
  )

  const Preview = () => (
    <div className="ProjectFeature-image">
      <a href={href} target="_blank" rel="noopener noreferrer">
        <div className="ProjectFeature-image-wrapper">
          <img src={image} alt={title} />
        </div>
      </a>
    </div>
  )

  return (
    <div className="ProjectFeature">
      <div className="ProjectFeature-title">
        <Title />
        <div className="ProjectFeature-links">
          <Links />
        </div>
      </div>
      <div className="ProjectFeature-content">
        <Preview />
        <p className="ProjectFeature-description">{description}</p>

        <div className="ProjectFeature-tags mono">
          {tags.map((t, i) => (
            <span key={i}>{t}</span>
          ))}
        </div>
      </div>
    </div>
  )
}

ProjectFeature.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  github: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
}

ProjectFeature.defaultProps = {
  title: 'Title',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In ornare quam viverra orci. Eget arcu dictum varius duis at consectetur lorem.',
  tags: ['Code', 'Design', 'Big brain', 'style', 'wow content', 'responsive design', 'super tech', 'magic sauce', 'bazookas'],
  github: 'https://github.com/mcli830',
  href: '#',
  image: 'https://source.unsplash.com/random/500x300',
}

export default ProjectFeature
