import React from 'react'
import { email } from '../data/info'

const HelloButton = () => {

  const greetings = ['Say hello', '问好', '挨拶する']
  const [state, setState] = React.useState(['center', 'bottom', 'top'])

  React.useEffect(() => {
    const nextPos = {
      top: 'bottom',
      center: 'top',
      bottom: 'center',
    }

    const interval = setInterval(() => {
      setState(state => state.map(pos => nextPos[pos]));
    }, 3000);

    return function cleanup() {
      clearInterval(interval)
    }
  }, [])

  return (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer" className='index-contact-hello button is-info is-outlined'>
      {greetings.map((text,i) => (
        <div key={i} className={`hello-text ${state[i]}`}>{text}</div>
      ))}
    </a>
  )
}

export default HelloButton
