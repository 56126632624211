import React from 'react'

import ProjectCard from './ProjectCard'

const ProjectList = ({ data }) => {

  const buttonRef = React.useRef()
  const scrollRef = React.useRef()
  const [more, setMore] = React.useState(false)

  const seeMore = () => {
    buttonRef.current.blur()
    setMore(true)
  }
  const seeLess = () => {
    buttonRef.current.blur()
    setMore(false)
  }

  React.useEffect(() => {
    const elem = scrollRef.current
    const cooldown = { timer: null }

    // listener -- applies scroll snap on scroll
    function activateSnap(e){
      elem.classList.add('scrollsnap-horizontal')
      if (cooldown.timer) {
        console.log('resetting timer')
        clearTimeout(cooldown.timer)
      }
      // remove scroll snap when scrolling is done
      cooldown.timer = setTimeout(() => {
        console.log('snap done')
        clearTimeout(cooldown.timer)
        cooldown.timer = null;
        elem.classList.remove('scrollsnap-horizontal')
      }, 50)
    }

    elem.addEventListener('scroll', activateSnap)

    // clean up
    return () => {
      if (cooldown.timer) clearTimeout(cooldown.timer)
      cooldown.timer = null
      elem.removeEventListener('scroll', activateSnap)
    }
  }, [])

  return (
    <div className='ProjectList'>
      <div ref={scrollRef} className={`card-container ${more ? 'more' : ''}`}>
        {data.map((p,i) => (
          <div className='card-wrapper' key={i}>
            <ProjectCard
              key={i}
              title={p.frontmatter.title}
              description={p.excerpt}
              tags={p.frontmatter.tags.split(', ')}
              href={p.frontmatter.href}
            />
          </div>
        ))}
      </div>
      <div className='more-button'>
        <button
          ref={buttonRef}
          className='button is-link is-outlined'
          onClick={more ? seeLess : seeMore}
        >
          {more ? 'See less' : 'See more'}
        </button>
      </div>
    </div>
  )
}

export default ProjectList
