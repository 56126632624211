import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from '../components/Section'
import Stamp from '../components/Stamp'
import ProjectFeature from '../components/ProjectFeature'
import ProjectList from '../components/ProjectList'
import HelloButton from '../components/HelloButton'

import { splitArray } from '../lib/array'

// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub, faLinkedin, faAngellist, faCodepen, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faArrowUp, faUser, faEnvelope, faBriefcase, faGlobeAmericas, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
library.add(
  faGithub, faLinkedin, faAngellist, faCodepen, faInstagram,
  faArrowUp, faUser, faEnvelope, faBriefcase, faGlobeAmericas,
  faExternalLinkAlt
)

const IndexPage = ({ data }) => {

  const skills = data.allSkillsYaml.nodes
  const projects = data.allMarkdownRemark.nodes
  const projectsFeatured = projects.filter(p => p.fields.sourceName === 'projectsFeatured').sort((a,b) => a.frontmatter.order - b.frontmatter.order)
  const projectsList = projects.filter(p => p.fields.sourceName === 'projectsList')

  const compactStyle = {
    width: 600,
    maxWidth: '100%',
  }

  return (
    <Layout>
      <SEO title="Home" />

      <Section id="home" containerClass="index-intro" size="md">
        <div className='intro-hi mono accent'>
          Hi, my name is
        </div>
        <h1 className="title is-1 display">
          <span className="highlight">
            Michael Li
            <span style={{opacity:0.6}}>,</span>
          </span>
          <br />
          <div className="primary">
            Full Stack Engineer.
          </div>
        </h1>
        <p style={compactStyle}>
          I make applications for the web with a focus on creating
          unique, intuitive user experiences and immense
          dedication to quality design.
        </p>
        <a href="#contact" className='button is-info is-outlined'>
          Let's talk
        </a>
        <div className='backstamp right'>
          <div className='transform'>
            <Stamp animated size={'35rem'} style={{
              right: 0, top: '50%',
              transform: 'translate(0, -50%)'
            }} />
          </div>
        </div>
      </Section>

      <Section
        id="about"
        title="About"
        containerClass="index-about"
        size="md"
        stamp
      >
        <p>
          I am a self-taught programmer with a childhood fascination
          for computers and science fiction. Passionate for quality
          UX design, I am devoted to crafting elegant and intuitive
          applications that are accessible to all and pleasing to
          use. As a programmer, I enjoy solving algorithms, systems
          design, data visualization, and building things from scratch.
        </p>
        <p>
          I mostly build for the web, specializing in JavaScript and
          dynamic frameworks such as React.
        </p>
        <div className='columns'>
          {splitArray(skills).map((pair,i) => (
            <div key={i} className='column is-half-tablet'>
              <div className='columns is-mobile'>
                {pair.map((node, ii) => (
                  <div key={ii} className='column is-half-mobile about-tool-list'>
                    <ul>
                      <h4 className='subheading'>{node.title.toUpperCase()}</h4>
                      {node.items.slice(1).map((item, iii) => (
                        <li key={iii}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Section>

      <Section
        id="projects"
        title="Projects"
        containerClass="index-project-features"
        size="md"
        stamp
      >
        {projectsFeatured.map((node,i) => (
          <ProjectFeature
            key={i}
            title={node.frontmatter.title}
            description={node.excerpt}
            tags={node.frontmatter.tags.split(', ')}
            github={node.frontmatter.github}
            href={node.frontmatter.href}
            image={node.frontmatter.image}
          />
        ))}
      </Section>

      <Section
        id="web"
        title="On the web"
        containerClass="index-project-cards"
        size='md'
        stamp
      >
        <p style={compactStyle}>
          I often use online tools such as <a href='https://codepen.io/mykolodon' target="_blank" rel="noopener noreferrer">Codepen</a> and <a href='https://glitch.com/@mcli830' target="_blank" rel="noopener noreferrer">Glitch</a> to build small projects and develop UI/UX ideas
          from scratch.
        </p>
        <ProjectList data={projectsList} />
      </Section>

      <Section
        id="contact"
        title="Contact"
        containerClass="index-contact"
        size='sm'
        stamp
      >
        <p>
          Want to know more?
          Have project inquiries?
          Need a language partner?
        </p>
        <p>
          Say hello!
          My mailbox is open to all.
        </p>
        <HelloButton />
      </Section>

    </Layout>
  )
}

export const query = graphql`
  query {
    allSkillsYaml(sort: {fields: order}) {
      nodes {
        title
        order
        items
      }
    }
    allMarkdownRemark {
      nodes {
        id
        excerpt(pruneLength: 500)
        frontmatter {
          type
          order
          title
          tags
          github
          href
          image
        }
        fields {
          sourceName
        }
      }
    }
  }
`

export default IndexPage
